<template>
  <vp-base-layout page-title="계좌번호 인증">
    <div class="l-wrapper">
      <div
        class="l-contents"
        style="margin: 50px 20px 50px 20px;">
        <p>입금받으실 계좌번호를 입력해주세요.</p>
        
        <ion-item style="margin-top: 50px">
          <ion-label
            fixed
            class="fixedLabel">
            은행
          </ion-label>
          <ion-select
            placeholder="은행선택"
            v-model="data.bank_code">
            <ion-item
              
              v-for="item in bankList.datas"
              :key="item.dictValue">
              <ion-select-option :value="item.dictValue">
                {{ item.dictLabel }}
              </ion-select-option>
            </ion-item>
          </ion-select>
        </ion-item>
        
        <ion-item>
          <ion-label
            fixed
            class="fixedLabel">
            예금주
          </ion-label>
          <ion-input
            v-model="data.acc_nm"
            placeholder="ex) 홍길동" 
            required="true"
            debounce="0" />
        </ion-item>
        <ion-item>
          <ion-label
            fixed
            class="fixedLabel">
            계좌번호
          </ion-label>
          <ion-input
            v-model="data.acc_num"
            type="number"
            placeholder="ex) 1234567890, '-'없이 숫자만" 
            required="true"
            debounce="0" />
        </ion-item>
        <ion-item fill="solid">
          <ion-label
            fixed
            class="fixedLabel">
            전화번호
          </ion-label>
          <ion-input
            v-model="data.order_tel_no"
            type="number"
            placeholder="ex) 01012345678" 
            inputmode="tel" 
            required="true"            
            readonly="true" />
        </ion-item>
        <ion-item>
          <ion-label
            fixed
            class="fixedLabel">
            비고(모델)
          </ion-label>
          <ion-input
            v-model="data.description"
            type="text"
            placeholder="ex) 갤럭시 S22 울트라 512G"/>
        </ion-item>
        <ion-label class="ion-text-wrap">
          <p>
            계좌 정보가 틀릴 경우 입금에 문제가 발생할 수 있습니다. 정확히 입력해주세요.
          </p>
        </ion-label>
      </div>
      <div class="card-button">
        <vp-button
          expand="full"
          @click="handlePage(data)">
          입력하고 다음
        </vp-button>
      </div>
    </div>
  </vp-base-layout>
</template>

<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { onMounted, ref } from 'vue'
import {
  IonLabel,
  IonInput,
  IonItem,
  IonSelect,
  IonSelectOption,
  useIonRouter,
  alertController
} from '@ionic/vue'

import VpPageTitle from '@/components//original/layouts/VpPageTitle.vue'
import { listData } from '@/api/mintit/agency'

const store = useStore()
const route = useRoute()
const ionRouter = useIonRouter()
//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const data = ref({
  bank_code: undefined,
  acc_nm: undefined,
  acc_num: undefined,
  order_tel_no: undefined,
  order_nm: undefined,
  description: undefined
})

const handleCheck = async(item) => {
    var str = ''
    if(item.bank_code == undefined) {
      str = '은행을 선택해주세요.'
    } else if(item.acc_nm == undefined) {
      str = '예금주를 입력해주세요.'
    } else if(item.acc_num == undefined) {
      str = '계좌를 입력해주세요.'
    } else if(item.order_tel_no == undefined) {
      str = '인증된 전화번호가 없습니다.'
    } else if(item.order_nm == undefined) {
      str = '인증된 이름이 없습니다.'
    }

    const alert = await alertController.create({
      //header: '약관동의',
      //subHeader: 'Important message',
      message: str,
      buttons: ['확인']
    })
    await alert.present()
}

const handlePage = (item) => {  
  if(item.bank_code == undefined || item.acc_nm == undefined || item.acc_num == undefined || item.order_tel_no == undefined) {
    handleCheck(item)
    return
  }
  
  store.dispatch('Customer/setAccountInfo', item)
  ionRouter.push('/mintit/agency/receipt')

}

const bankList = ref({})
const queryParams = {
  pageNum: 1,
  pageSize: 9999,
  dictName: undefined,
  dictType: undefined,
  status: undefined
}
const fetchList = async () => {
  queryParams.dictType = 'bank_list'
  const response = await listData(queryParams)
  const { results, count } = response.data
  bankList.value.datas = results
  bankList.value.count = count
}
//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  //data.value.order_tel_no = route.params.order_tel_no
  data.value.order_tel_no = route.query.mobileno
  data.value.order_nm = decodeURIComponent(route.query.name)
  fetchList()
})
</script>

<style scoped>
ion-select{
    max-width: 80% !important;
}
.fixedLabel {
    min-width: 30% !important;
    max-width: 30% !important;
}
</style>